import styled from 'styled-components'
import { Link } from 'gatsby'
import { xPad } from '../../utils/theme'
import { transparentize } from 'polished'

const Bg = styled.div`
  position: relative;
  > div {
    position: absolute;
    left: 0;
    top: 50%;
    right: 0;
    transform: skewY(-12deg);
    pointer-events: none;
    top: calc(50% + 400px);

    .stripe {
      height: 200px;
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
    }

    .s0 {
      height: 5000px;
      bottom: 200px;
      background: linear-gradient(90deg, #e4ecf5, #f5fdff);
    }

    .s1 {
      bottom: 0;
      left: calc(50% + 220px);
      background: linear-gradient(90deg, #f4fafd, #eff8fc);
    }

    .s2 {
      bottom: 200px;
      right: calc(50% - 220px);
      background: linear-gradient(90deg, #d6e3f0, #e7f3fa);
    }

    .s3 {
      bottom: 600px;
      left: 10%;
      right: calc(50% - 300px);

      background: linear-gradient(90deg, #ecf4fa, #e4eff6);
    }
  }
`
export const Navigation = styled.div`
  background-color: #fff;
  z-index: 99;
  display: flex;
  justify-content: center;
  margin-top: 7rem;
  padding: 2rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  flex-wrap: wrap;

  a {
    margin: 0.5rem 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }

  a {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 0 2rem;
    opacity: 0.7;
    font-weight: 600;
  }
`
export const Customer = styled.div`
  background: #f4f4f4;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  display: flex;
  margin: 3rem 0 5rem;

  width: 90%;
  margin-left: auto;
  margin-right: auto;
  min-height: 370px;

  .gatsby-image-outer-wrapper {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
      height: 100%;
    }
  }
`

interface IContentProps {
  color: string
}
export const Content = styled<IContentProps, any>('div')`
  box-sizing: border-box;
  display: flex;
  max-width: 100%;
  background: ${props => transparentize(0.2, props.color)};
  min-height: 370px;
  justify-content: center;
  flex-direction: column;
  color: #fff;
  font-size: 1.3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  z-index: 10;
  ${xPad};

  @media (min-width: 800px) {
    max-width: 65%;
    background: ${props => transparentize(0.1, props.color)};
  }

  @media (min-width: 960px) {
    max-width: 45%;
  }

  p {
    margin-top: 1rem;
    font-style: italic;
  }

  svg {
    width: 46px;
    height: auto;
  }
`

export const AfterContent = styled.div`
  display: flex;
  width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
  > div {
    ${xPad};
    width: 50%;
    flex-grow: 1;
    font-size: 1.1rem;
    margin-bottom: 3rem;

    @media (max-width: 959px) {
      width: 100%;
    }

    img {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 959px) {
    flex-direction: column;
  }

  @media (max-width: 639px) {
    width: 100%;
  }
`
const ReadMore = styled(Link)`
  display: block;
  padding-top: 1rem;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.purple};
`

export const CasesContainer = styled.section`
  h1 {
    text-align: center;
  }
`
